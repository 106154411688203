import {useEffect, useState} from 'react';
export enum WindowSizeEnum{
    Desktop = 1601,
    Tablet = 1600,
    Mobile = 767
}

interface useWindowResizeProps{
    currentSize : WindowSizeEnum,
    isDesktop : boolean,
    isMobile : boolean,
    isTablet: boolean,
    isDesktopOrTablet : boolean,
    isTabletOrMobile : boolean
    value : number
}

export const useWindowResize = () : useWindowResizeProps => {

    const [currentSize, setCurrentSize ] = useState(WindowSizeEnum.Desktop)
    const [value, setValue ] = useState(0)

    const handleResize = () => {
        setValue(window.innerWidth)
        if(window.innerWidth <= WindowSizeEnum.Mobile){
            setCurrentSize(WindowSizeEnum.Mobile)
        }else{
            if(window.innerWidth <= WindowSizeEnum.Tablet ){
                setCurrentSize(WindowSizeEnum.Tablet)
            }
        }
        if(window.innerWidth > WindowSizeEnum.Tablet){
            setCurrentSize(WindowSizeEnum.Desktop)
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return {
        currentSize : currentSize,
        isDesktop : currentSize === WindowSizeEnum.Desktop,
        isMobile : currentSize === WindowSizeEnum.Mobile,
        isTablet : currentSize === WindowSizeEnum.Tablet,
        isDesktopOrTablet : ( currentSize === WindowSizeEnum.Desktop ||  currentSize === WindowSizeEnum.Tablet),
        isTabletOrMobile : ( currentSize === WindowSizeEnum.Tablet ||  currentSize === WindowSizeEnum.Mobile),
        value: value
    }
}
