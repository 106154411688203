import React, {useState} from 'react';
import {useWindowResize} from "./hooks/useWindowResize";
import {
    Container,
    ContainerMobile,
    Description,
    Image,
    ImageContainer,
    Info,
    Link,
    LinkText,
    Slide,
    SlideMobile,
    Subtitle
} from "./styles";
import {
    ArrowRightBlack,
    ArrowRightWhite,
    BlackArrow, DeckAirLogo,
    DeckAirLogoSmall, DeckAuthLogo,
    DeckAuthLogoSmall,
    DeckLogo,
    DeckLogoSmall, WhiteArrow
} from './Icons';
import {TabEnum} from "./enum";

// const intervals = [0, 0.25, 0.5, 0.75, 1];
// const scale = [1, 1.5, 1, 2.5, 1];


const DeckDescription = 'Команда профессионалов в проектировании и разработке программного обеспечения'
const DeckAirDescription = 'Система управления операционной деятельностью аэропорта'
const DeckAuthDescription = 'Система управления политиками сетевых подключений'

function App() {

    // const {animation} = useSpring({
    //     from: {
    //         animation: 0
    //     },
    //     animation: 1
    // });

    const {isMobile, value} = useWindowResize()

    // const style = {
    //     transform: animation
    //         .to({
    //             range: intervals,
    //             output: scale
    //         })
    //         .to(output => `scale(${output})`),
    //
    // } as unknown as CSSProperties;

    const grade = Number(((value - 700) / 100).toFixed())

    const zoom = (): number => {
        if (grade < 10) {
            if (grade < 5) {
                return 0.5
            }

            return Number('0.' + grade)
        }
        return 1
    }

    const [currentTab, setCurrentTab] = useState(TabEnum.main)

    return isMobile
        ? <ContainerMobile>
            <SlideMobile bg={'white'}>
                <DeckLogoSmall/>
                <Subtitle>
                    {DeckDescription}
                </Subtitle>
                <Link
                    href={'https://deck.lc/'}
                    target={'_blank'}
                    rel={'noreferrer'}>
                    <LinkText>
                        Перейти на сайт
                    </LinkText>
                    <ArrowRightBlack/>
                </Link>
                <Image src={'/images/team.png'} alt={'team'}/>
            </SlideMobile>
            <SlideMobile bg={'#59B2FF'}>
                <DeckAirLogoSmall/>
                <Subtitle>
                    {DeckAirDescription}
                </Subtitle>
                <Link
                    href={'https://air.deck.lc/'}
                    target={'_blank'}
                    rel={'noreferrer'}>
                    <LinkText>
                        Перейти на сайт
                    </LinkText>
                    <ArrowRightBlack/>
                </Link>
                <Image src={'/images/plane.png'} alt={'plane'}/>
            </SlideMobile>
            <SlideMobile bg={'black'}>
                <DeckAuthLogoSmall/>
                <Subtitle style={{color: 'white'}}>
                    {DeckAuthDescription}
                </Subtitle>
                <Link
                    href={'https://auth.deck.lc/'}
                    target={'_blank'}
                    rel={'noreferrer'}>
                    <LinkText style={{color: 'white'}}>
                        Перейти на сайт
                    </LinkText>
                    <ArrowRightWhite/>
                </Link>
                <Image src={'/images/router.png'} alt={'router'}/>
            </SlideMobile>
        </ContainerMobile>
        : <Container>
            <Slide
                active={currentTab === TabEnum.main ? 1 : 0}
                bg={'white'}
                onMouseEnter={() => (currentTab !== TabEnum.main) && setCurrentTab(TabEnum.main)}
                style={{zoom: zoom()}}
                href={'https://deck.lc/'}
                target={'_blank'}
                rel={'noreferrer'}>
                <Info>
                    {currentTab === TabEnum.main && <BlackArrow/>}
                    <Description>
                        <DeckLogo/>
                        {currentTab === TabEnum.main && <Subtitle>
                            {DeckDescription}
                        </Subtitle>}
                    </Description>
                </Info>
                {currentTab === TabEnum.main && <ImageContainer>
                    <Image src={'/images/team.png'} alt={'team'}/>
                </ImageContainer>}
            </Slide>
            <Slide
                active={currentTab === TabEnum.air ? 1 : 0}
                bg={'#59B2FF'}
                onMouseEnter={() => (currentTab !== TabEnum.air) && setCurrentTab(TabEnum.air)}
                style={{zoom: zoom()}}
                href={'https://air.deck.lc/'}
                target={'_blank'}
                rel={'noreferrer'}>
                <Info>
                    {currentTab === TabEnum.air && <BlackArrow/>}
                    <Description>
                        <DeckAirLogo/>
                        {currentTab === TabEnum.air && <Subtitle>
                            {DeckAirDescription}
                        </Subtitle>}
                    </Description>
                </Info>
                {currentTab === TabEnum.air && <ImageContainer>
                    <Image src={'/images/plane.png'} alt={'plane'}/>
                </ImageContainer>}
            </Slide>
            <Slide
                active={currentTab === TabEnum.auth ? 1 : 0}
                bg={'black'}
                onMouseEnter={() => (currentTab !== TabEnum.auth) && setCurrentTab(TabEnum.auth)}
                style={{zoom: zoom()}}
                href={'https://auth.deck.lc/'}
                target={'_blank'}
                rel={'noreferrer'}>
                <Info>
                    {currentTab === TabEnum.auth && <WhiteArrow/>}
                    <Description>
                        <DeckAuthLogo/>
                        {currentTab === TabEnum.auth && <Subtitle style={{color: 'white'}}>
                            {DeckAuthDescription}
                        </Subtitle>}
                    </Description>
                </Info>
                {currentTab === TabEnum.auth && <ImageContainer>
                    <Image src={'/images/router.png'} alt={'router'}/>
                </ImageContainer>}
            </Slide>
        </Container>
}


export default App;
